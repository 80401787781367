import * as _toConsumableArray3 from "@babel/runtime/helpers/toConsumableArray";
var _toConsumableArray2 = "default" in _toConsumableArray3 ? _toConsumableArray3.default : _toConsumableArray3;
import * as _dashify2 from "dashify";
var _dashify = "default" in _dashify2 ? _dashify2.default : _dashify2;
import * as _indefiniteArticle2 from "indefinite-article";
var _indefiniteArticle = "default" in _indefiniteArticle2 ? _indefiniteArticle2.default : _indefiniteArticle2;
var exports = {};
(function (global, factory) {
  factory(exports, _toConsumableArray2, _dashify, _indefiniteArticle);
})(exports, function (exports, _toConsumableArray, dashify, indefiniteArticle) {
  'use strict';

  var applyModifiers = function applyModifiers(name, modifiers) {
    if (modifiers === undefined) {
      return name;
    }
    return modifiers.reduce(function (modifiedName, modifier) {
      if (modifier === "capitalize") {
        var head = modifiedName.charAt(0).toUpperCase();
        var tail = modifiedName.slice(1);
        return "".concat(head).concat(tail);
      }
      if (modifier === "dashify") {
        return dashify(modifiedName);
      }
      if (modifier === "prependIndefiniteArticle") {
        return "".concat(indefiniteArticle(modifiedName), " ").concat(modifiedName);
      }
      return modifiedName;
    }, name);
  };
  var buildRegex = function buildRegex(variable) {
    var expression = variable.name + variable.modifiers.map(function (modifier) {
      return "\\.".concat(modifier, "\\(\\)");
    }).join("");
    return new RegExp("\\$\\{".concat(expression, "}"), "g");
  };
  var preRenderString = function preRenderString(string, parameters) {
    var expressionRegex = /\${([^.}]+)((\.[^(]+\(\))*)}/g;
    var variables = [];
    var expressionResult = expressionRegex.exec(string);
    while (expressionResult !== null) {
      var variable = {
        modifiers: [],
        name: expressionResult[1]
      };
      if (expressionResult[3] !== undefined) {
        var modifiersRegex = /\.[^(]+\(\)/g;
        var modifiersRegexResult = modifiersRegex.exec(expressionResult[2]);
        while (modifiersRegexResult !== null) {
          variable.modifiers.push(modifiersRegexResult[0].slice(1, -2));
          modifiersRegexResult = modifiersRegex.exec(expressionResult[2]);
        }
      }
      variables.push(variable);
      expressionResult = expressionRegex.exec(string);
    }
    var preRenderedParts = variables.reduce(function (parts, variable) {
      return parts.map(function (part) {
        if (typeof part === "string") {
          return part.split(buildRegex(variable)).reduce(function (prts, prt, index) {
            if (index === 0) {
              return [prt];
            }
            if (variable.name in parameters) {
              return [].concat(_toConsumableArray(prts), [applyModifiers(parameters[variable.name], variable.modifiers), prt]);
            }
            return [].concat(_toConsumableArray(prts), [function (prmtrs) {
              return applyModifiers(prmtrs[variable.name], variable.modifiers);
            }, prt]);
          }, []);
        }
        return [part];
      }).reduce(function (prts, part) {
        return [].concat(_toConsumableArray(prts), _toConsumableArray(part));
      }, []);
    }, [string]);
    return function (missingParameters) {
      return preRenderedParts.reduce(function (renderedParts, preRenderedPart) {
        if (typeof preRenderedPart === "string") {
          return [].concat(_toConsumableArray(renderedParts), [preRenderedPart]);
        }
        return [].concat(_toConsumableArray(renderedParts), [preRenderedPart(missingParameters)]);
      }, []).join("");
    };
  };
  var compile = function compile(template) {
    var knownParameters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var renderCode = template.code === undefined ? undefined : preRenderString(template.code, knownParameters);
    var renderMessage = template.message === undefined ? undefined : preRenderString(template.message, knownParameters);
    function render() {
      var causeOrMissingParameters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var optionalCause = arguments.length > 1 ? arguments[1] : undefined;
      var hasNoOptionalCause = optionalCause === undefined && (causeOrMissingParameters instanceof Error || causeOrMissingParameters.code !== undefined && causeOrMissingParameters.code.slice(-9) === "Exception");
      var _ref = hasNoOptionalCause ? {
          cause: causeOrMissingParameters,
          missingParameters: {}
        } : {
          cause: optionalCause,
          missingParameters: causeOrMissingParameters
        },
        cause = _ref.cause,
        missingParameters = _ref.missingParameters;
      var err = renderMessage === undefined ? new Error() : new Error(renderMessage(missingParameters));
      if (cause !== null) {
        err.cause = cause;
      }
      if (renderCode !== undefined) {
        err.code = renderCode(missingParameters);
      }
      if (template.status !== undefined) {
        err.status = template.status;
      }
      return err;
    }
    return render;
  };
  exports.compile = compile;
});
export default exports;
export const compile = exports.compile;